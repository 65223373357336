body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.deck-app-name-cls {
    width: 5rem;
    color: #FFFFFF;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
}

.deck-app-icon-cls {
    width: 5rem;
    height: 5rem;
    background-color: #659999;
    border-radius: 16px;
}

.deck-app-cls {
    width: 5rem;
    text-align: center;
    margin: auto;
    cursor: pointer;
}

.phone-cls {
    margin: auto;
    width: 25rem;
    height: 45rem;
    border-radius: 35px;
    border: 0.5rem solid #000000;
}

.phone-deck-cls {
    border-radius: 26px;
    width: 100%;
    height: 100%;
    background: #aa4b6b;
    background: -webkit-linear-gradient(to right, #3b8d99, #6b6b83, #aa4b6b);
    background: linear-gradient(to right, #3b8d99, #6b6b83, #aa4b6b);

    .phone-deck-app-grid-cls {
        padding: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-row-gap: 0.5rem
    }
}

.empty-app-cls {
    border-radius: 26px;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    text-align: center;
}


.todo-app {
    height: 100%;
    position: relative;

    .todo-app-title {
        text-align: left;
        font-size: 3rem;
        font-weight: bolder;
        padding-left: 1rem;
        padding-bottom: 1rem;
    }

    .todo-app-items {
        padding-left: 1rem;

        .finish-item {
            color: #979797;
            text-decoration: line-through;
        }

        .default-item {
            cursor: pointer;
        }

        .todo-app-todo-item {
            margin-bottom: 6px;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
        }

        .todo-item-icon {
            border: 2px solid #3bb16c;
            border-radius: 50%;
            width: 20px;
            height: 20px;
        }

        .todo-item-value {
            padding-left: 6px;
            line-height: 24px;
        }
    }

    .todo-add-btn {
        position: absolute;
        bottom: 3rem;
        margin: auto;
        display: inline-block;
        left: 0;
        right: 0;
        cursor: pointer;
    }
}

.chat-app {
    height: 100%;
    position: relative;

    .chat-app-title {
        text-align: left;
        font-size: 3rem;
        font-weight: bolder;
        padding-left: 1rem;
        padding-bottom: 1rem;
    }

    .record-list {
        max-height: 35rem;
        overflow-y: scroll;
        padding: 0.5rem;
    }
}